.wrap {
	width: 490px;
	margin: 0 auto;
	position: relative;
}

.wrap .bg {
	width: 450px;
	padding: 20px;
}

.wrap .sun {
	width: 140px;
	height: 140px;
	position: absolute;
	top: 20px;
	right: 10px;
	animation: rotate-sun 60s infinite linear;
	-webkit-animation: rotate-sun 60s infinite linear;
	-moz-animation: rotate-sun 60s infinite linear;
}

.wrap .cloud {
	position: absolute;
	width: 120px;
	height: 70px;
}

.cloud.cloud-1 {
	top: 150px;
	left: -20px;
}
.cloud.cloud-2 {
	top: 40px;
	left: 150px;
	animation: animate-cloud 14s infinite linear;
	-webkit-animation: animate-cloud 14s infinite linear;
	-moz-animation: animate-cloud 14s infinite linear;
}
.cloud.cloud-3 {
	top: 190px;
	right: -20px;
	animation: animate-cloud 8s infinite linear;
	-webkit-animation: animate-cloud 8s infinite linear;
	-moz-animation: animate-cloud 8s infinite linear;
}

.rain {
	position: absolute;
	width: 20px;
	height: 120px;
	overflow: none;
	top: 160px;
}
.drop {
	position: inherit;
	top: calc(50%);
	height: 10px;
	width: 10px;
	border-radius: 50%;
	background-color: powderblue;
	animation: raining 1s infinite;
}

.drop-1::before,
.drop-2::before,
.drop-3::before,
.drop-4::before,
.drop-5::before {
	content: '';
	position: relative;
	top: -37px;
	width: 0;
	height: 0;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-bottom: 23px solid powderblue;
}

.drop-1 {
	left: calc(50% - 10px);
	animation-delay: 1s;
}

.drop-2 {
	left: calc(50% + 5px);
	animation-delay: 0.5s;
}

.drop-3 {
	left: calc(50% + 20px);
	animation-delay: 0.75s;
}

.drop-4 {
	left: calc(50% + 35px);
	animation-delay: 0.25s;
}

.drop-5 {
	left: calc(50% + 50px);
	animation-delay: 0.4s;
}

@keyframes rotate-sun {
	0% {
		-webkit-transform: rotateZ(0);
		transform: rotateZ(0);
	}
	100% {
		-webkit-transform: rotateZ(-360deg);
		transform: rotateZ(-360deg);
	}
}

@keyframes animate-cloud {
	0% {
		transform: translateX(0%);
	}
	25% {
		transform: translateX(10%);
	}
	50% {
		transform: translateX(20%);
	}
	75% {
		transform: translateX(10%);
	}
	100% {
		transform: translateX(0%);
	}
}

@keyframes raining {
	0% {
		opacity: 1;
		transform: translateY(0px);
	}
	100% {
		opacity: 0;
		transform: translateY(70px);
	}
}

#smoke {
	position: absolute;
	z-index: 3;
	width: 1px;
	height: 160px;
	left: 50%;
	bottom: 30px;
}

/* smoke balls */

#smoke span {
	display: block;
	position: absolute;
	bottom: -35px;
	left: 50%;
	margin-left: -20px;
	height: 0px;
	width: 0px;
	border: 35px solid #4b4b4b;
	border-radius: 35px;
	left: -14px;
	opacity: 0;
	transform: scale(0.2);
}

/* Smoke animation */

@keyframes smokeL {
	0% {
		transform: scale(0.2) translate(0, 0);
	}
	10% {
		opacity: 1;
		transform: scale(0.2) translate(0, -5px);
	}
	100% {
		opacity: 0;
		transform: scale(1) translate(-20px, -130px);
	}
}

@keyframes smokeR {
	0% {
		transform: scale(0.2) translate(0, 0);
	}
	10% {
		opacity: 1;
		transform: scale(0.2) translate(0, -5px);
	}
	100% {
		opacity: 0;
		transform: scale(1) translate(20px, -130px);
	}
}

#smoke .s0 {
	animation: smokeL 10s 0s infinite;
}
#smoke .s1 {
	animation: smokeR 10s 1s infinite;
}
#smoke .s2 {
	animation: smokeL 10s 2s infinite;
}
#smoke .s3 {
	animation: smokeR 10s 3s infinite;
}
#smoke .s4 {
	animation: smokeL 10s 4s infinite;
}
#smoke .s5 {
	animation: smokeR 10s 5s infinite;
}
#smoke .s6 {
	animation: smokeL 10s 6s infinite;
}
#smoke .s7 {
	animation: smokeR 10s 7s infinite;
}
#smoke .s8 {
	animation: smokeL 10s 8s infinite;
}
#smoke .s9 {
	animation: smokeR 10s 9s infinite;
}
